/**
 * Change error message to reload page.
 */

import ContentWrapper from 'SourceComponent/ContentWrapper';
import {ReactElement} from 'SourceType/Common.type';
import 'SourceRoute/SomethingWentWrong/SomethingWentWrong.style';
import {
    SomethingWentWrongComponent as SourceSomethingWentWrongComponent
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component'
import BrowserDatabase from "Util/BrowserDatabase/BrowserDatabase";

/** @namespace Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends SourceSomethingWentWrongComponent {

    render(): ReactElement {
        const {onClick} = this.props;

        //Maskinia - reload first time, second time show error.
        const secondSomethingWentWrong = BrowserDatabase.getItem<boolean>("SecondSomethingwentWrong") || false;
        if (!secondSomethingWentWrong) {
            //Set SecondSomethingwentWrong == true
            BrowserDatabase.setItem(true, "SecondSomethingwentWrong");
            //Maskinia - make reload on page instead of showing error massage!
            window.location.reload();
            return;
        } else {
            BrowserDatabase.setItem(false, "SecondSomethingwentWrong");
        }

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <h1 block="SomethingWentWrong" elem="Heading">{__('Ooops!')}</h1>
                    <h2 block="SomethingWentWrong" elem="SubHeading">{__('Something went wrong!')}</h2>
                    { /* eslint-disable-next-line react/forbid-elements */}
                    <a
                        href="/"
                        block="SomethingWentWrong"
                        elem="Button"
                        mix={{block: 'Button'}}
                        onClick={onClick}
                    >
                        {__('Back to homepage')}
                    </a>
                    {this.renderErrorDetails()}
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrongComponent;
